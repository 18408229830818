<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      <table width="100%">
        <tr>
          <td>
            Top Audio Fingerprints <span style="margin-left: 6px; font-size: 10px; font-weight: 600">last 24 hours</span>
          </td>
          <td align="right">
            <div v-if="isDownloadingRadars">
              <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
            <div v-else-if="errorMessage == null">
              <CButton color="primary" style="margin: -10px 0px -10px 0px; text-transform: capitalize;" @click="clickedRadars()">
                <table>
                  <tr>
                    <td>
                      {{ nameForRadar(currentRadar) }}
                    </td>
                    <td>
                      <div style="font-size: 30px; margin: -15px -5px -10px 0px">&#9662;</div>
                    </td>
                  </tr>
                </table>
              </CButton>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isDownloadingFingerprints || isDownloadingProfiles">
      <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-else>
      <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
        <thead>
          <tr style="font-size: 13px; border: 2px solid transparent">
            <th style="text-align:left">Calls</th>
            <th style="text-align:left">Name</th>
            <th style="text-align:left">Category</th>
            <th style="text-align:center; width: 30px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in results">
            <td>
              {{ result.destinations.toLocaleString() }}
            </td>
            <td>
              {{ nameForBucket(result) }}
            </td>
            <td>
              {{ categoryForBucket(result) }}
            </td>
            <td>
              <CButton color="primary" @click="clickedBucket(result)">
                <CIcon name="cil-volume-high" style="width: 20px; height: 20px" />
              </CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <CModal title="Select A Radar" :show.sync="displayRadars">
    <span v-for="radar in radars">
      <a href="#" @click.prevent="selectedRadar(radar)">{{ radar.name }}</a><br>
    </span>
    <template #footer-wrapper><span></span></template>
  </CModal>

  <CModal title="Audio" :show.sync="displayAudio" @update:show="closedAudio">
    <div v-if="isDownloadingAudio">
      <div class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div v-else>
      {{ displayedProfile.transcription }}
      <hr>
      <audio controls id="profile-audio-recording">
        <source :src="displayedProfile.recording">
        Your browser does not support the audio element.
      </audio>
    </div>
    <template #footer-wrapper><span></span></template>
  </CModal>

</div>
</template>

<script>
export default {
  name: 'Audio-Fingerprints',
  data() {
    return {
      currentRadar: this.defaultRadar(),
      displayRadars: false,
      displayAudio: false,
      displayedProfile: {},
      errorMessage: null,
      isDownloadingProfiles: false,
      isDownloadingRadars: false,
      isDownloadingFingerprints: false,
      isDownloadingAudio: false,
      languageNames: null,
      profiles: {},
      radars: [],
      regionNames: null,
      results: [],
      showSenderMessages: false,
      selectedBucket: null
    }
  },
  methods: {
    closedAudio() {
      var sound = document.getElementById("profile-audio-recording");
      sound.pause();
      sound.currentTime = 0;
    },
    //--------------------------------------------------------------------------
    clickedBucket(bucket) {

      if (this.isDownloadingAudio) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingAudio = true;
        this.selectedBucket = bucket;
        this.displayAudio = true;
      }

      // Fetch the bucket profile
      var body = JSON.stringify({
        "buckets": [bucket.bucket],
      });
      var that = this;
      var method = "POST";
      var url = "https://fingerprint-profile.platform.robocalls.ai/buckets/bulk-lookup";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingAudio = false;
          if (code != 200) {
            // Something went wrong
            that.displayAudio = false;
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processAudio(result);
            } catch (err) {
              that.displayAudio = false;
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingAudio = false;
          that.displayAudio = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingAudio = false;
          that.displayAudio = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    clickedRadars() {
      this.displayRadars = true;
    },
    //--------------------------------------------------------------------------
    defaultRadar() {
      return {
        tag: 'GLOBAL',
        name: "Global"
      }
    },
    //--------------------------------------------------------------------------
    downloadRadars() {
      if (this.isDownloadingRadars) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingRadars = true;
      }

      // Fetch the radars
      var that = this;
      var method = "GET";
      var url = "https://radar.platform.robocalls.ai/tags";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingRadars = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processRadars(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingRadars = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingRadars = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    downloadProfiles(fingerprints) {

      if (this.isDownloadingProfiles) {
        return;
      } else {
        this.profiles = {};
        this.errorMessage = null;
        this.isDownloadingProfiles = true;
      }


      var buckets = "";
      for (let i = 0; i < fingerprints.length; i++) {
        buckets += fingerprints[i].bucket + ",";
      }

      var that = this;
      var method = "GET";
      var url = "https://identity.platform.robocalls.ai/buckets?" + buckets;
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, "", requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingProfiles = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the profiles
            try {
              var result = JSON.parse(body);
              that.processProfiles(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingProfiles = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    nameForBucket(bucket) {
      var name = "";
      if (this.profiles[bucket.bucket]) {
        name = this.profiles[bucket.bucket].name;
      } else {
        name = "?";
      }
      return name;
    },
    //--------------------------------------------------------------------------
    categoryForBucket(bucket) {
      var category = "";
      if (this.profiles[bucket.bucket]) {
        category = this.profiles[bucket.bucket].category;
      } else {
        category = "?";
      }
      return category;
    },
    //--------------------------------------------------------------------------
    downloadFingerprints(radar) {
      if (this.isDownloadingFingerprints) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingFingerprints = true;
        this.results = [];
      }

      // Fetch the senders
      var that = this;
      var method = "GET";
      var url = "https://radar.platform.robocalls.ai/top-fingerprints?tag=" + encodeURIComponent(radar.tag);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingFingerprints = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processFingerprints(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingFingerprints = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingFingerprints = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
    //--------------------------------------------------------------------------
    nameForCountry(country) {
      switch (country.toUpperCase()) {
        case "HK":
          return "Hong Kong SAR China";
        default:
          try {
            return this.regionNames.of(country.toUpperCase());
          } catch (error) {
            return country;
          }
      }
    },
    //--------------------------------------------------------------------------
    nameForLanguage(language) {
      try {
        return this.languageNames.of(language.toLowerCase());
      } catch (error) {
        return country;
      }
    },
    //--------------------------------------------------------------------------
    nameForRadar(radar) {
      return radar.name;
    },
    //--------------------------------------------------------------------------
    openDomainProfile(domain) {
      var url = "http://admin.robocalls.ai/#/sms-profile/domain/" + domain;
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    processAudio(response) {
      // Make sure we received results
      if (!response || !response.profiles || response.profiles.length == 0) {
        this.errorMessage = "No profiles were returned";
        return;
      }

      this.displayedProfile = response.profiles[0];
    },
    //--------------------------------------------------------------------------
    processProfiles(response) {
      // Make sure we received results
      if (!response || !response.buckets) {
        this.errorMessage = "No profiles were returned";
        return;
      }

      var profiles = {};
      for (var x = 0; x < response.buckets.length; x++) {
        profiles[response.buckets[x].bucket] = response.buckets[x];
      }
      this.profiles = profiles;
    },
    //--------------------------------------------------------------------------
    processRadars(response) {

      // Make sure we received radars
      if (!response || !response.fingerprints) {
        this.errorMessage = "No radars were returned";
        return;
      }

      this.radars = [];

      for (var x = 0; x < response.fingerprints.length; x++) {
        var radar = response.fingerprints[x];
        this.radars.push(radar);
      }
    },
    //--------------------------------------------------------------------------
    processFingerprints(response) {
      // Make sure we received results
      if (!response || !response.fingerprints) {
        this.errorMessage = "No results were returned";
        return;
      }

      var results = response.fingerprints;

      // Sort the results
      results.sort((a, b) => {
        if (a.destinations < b.destinations) {
          return 1;
        } else {
          return -1;
        }
      });
      this.results = results;

      // Download the profiles for each fingerprint
      this.downloadProfiles(results);
    },
    //--------------------------------------------------------------------------
    radarCategories() {
      var categories = [];
      this.radars.forEach(function(value, key) {
        categories.push(key);
      });
      return categories;
    },
    //--------------------------------------------------------------------------
    radarsForCategory(category) {
      if (!this.radars.has(category)) {
        return [];
      } else {
        return this.radars.get(category).sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    },
    //--------------------------------------------------------------------------
    selectedRadar(radar) {
      this.currentRadar = radar;
      this.displayRadars = false;
      this.downloadFingerprints(radar);
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    truncateMessage(message) {
      if (message == null) {
        return "";
      }
      if (message.length > 85) {
        message = message.substring(0, 85) + "...";
      }
      return message;
    }
  },
  mounted() {
    this.regionNames = new Intl.DisplayNames(['en'], {
      type: 'region'
    });
    this.languageNames = new Intl.DisplayNames(['en'], {
      type: 'language'
    });
    this.downloadRadars();
    this.downloadFingerprints(this.currentRadar);
  }
}
</script>
